<template>
  <div id="app">
    <Menu />
    <div style="max-width: 400px;"><router-view></router-view></div>
    <NotificationTxt />
    <Footer />
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import NotificationTxt from './components/NotificationTxt.vue';

export default {
  name: 'App',
  components: {
    Footer,
    Menu,
    NotificationTxt
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
@font-face {
  font-family: 'font1';  
  src: url('~@/assets/fonts/font.ttf') format('truetype');    
  font-weight: normal;
  font-style: normal;
}
button{
  font-family: 'font1', Avenir, Helvetica, Arial, sans-serif;
}
p{
  font-family: 'font1', Avenir, Helvetica, Arial, sans-serif;
}
input{
  font-family: 'font1', Avenir, Helvetica, Arial, sans-serif;
}
.catFont{
  font-family: 'font1', Avenir, Helvetica, Arial, sans-serif;
}
h1 {
  text-align: center;
}

#app {
  display: flex;  
  flex-direction: column;  
  justify-content: center;  
  align-items: center; 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: white;
  background-size: cover;
  background-position: center;
  
}

</style>

