<template>
    <div v-if="notification.visible" class="notification catFont">
      ⚠️{{ notification.message }}
    </div>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  
  export default {
    setup() {
      const store = useStore();
      
      const notification = computed(() => store.state.notification);
  
      return { notification };
    }
  };
  </script>
  
  <style>
.notification {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #000000;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  text-align: center;
  z-index: 1000;
  width: 100%;
  min-height: 40px;
  font-size: 1.5rem;
}
  </style>