<template>

  <div id="container" class="catFont">
    <img  src="@/assets/logo.png" alt="Diam" class="logo"/>
    <div id="walletBalance" v-if="$store.state.wallet.length > 0">
      <p @click="copy($store.state.wallet[0].address)" class="adr" >{{ $store.state.wallet[0].address }}</p>
      <p class="bal" >{{ $store.state.balance }}</p>
    </div>
    

      <nav>
        <router-link to="/wallet" v-if="!$store.state.wallet.length > 0"><h1>LUMX</h1></router-link>
        <router-link to="/wallet" v-if="$store.state.wallet.length > 0">Wallet</router-link>
        <router-link to="/mint" v-if="$store.state.wallet.length > 0">Mint</router-link>
        <router-link to="/deploy" v-if="$store.state.wallet.length > 0 ">Deploy</router-link>
        <router-link to="/multinft" v-if="$store.state.wallet.length > 0 && ($store.state.wallet[0].address === 'DAMZQVCZQLZFRkggDDY4ciNQpA6LDuQJzD' ||  $store.state.wallet[0].address === 'DUAEHbj1G2iGrRMEFq3X4qpTArRebdDizi' || $store.state.wallet[0].address === 'DFTGJkEGc8oEMYwDevpA5ZbuPxtVfTY722')">admin</router-link>
        <router-link to="/nft" v-if="$store.state.wallet.length > 0 ">Nft</router-link>
        <router-link to="/games" v-if="$store.state.wallet.length > 0 ">Games</router-link>
        <router-link to="/withdraw" v-if="$store.state.wallet.length > 0 ">Withdraw</router-link>
      </nav>

  </div>
</template>
  
  <script>
  export default {
    name: 'MenuC',
    methods:{
      copy(copy) {
        navigator.clipboard.writeText(copy).then(() => {
          this.$store.commit('afficherNotification', 'Copy to clipboard !');
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      },
    }
  }
  </script>
  
  <style scoped>
#container {
 position:fixed;
 top: 0px;
 width: 100%;
 background-color: #fff;
 box-sizing: border-box;
}

#walletBalance {
  font-size: smaller;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
   
}

.adr,
.bal {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

  nav {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 5px;
    color: #555;
    
  }
  
  router-link {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  router-link:hover {
    background-color: #555;
  }
  
  @media (max-width: 600px) {
    nav {
      flex-direction:row;
    }
  }

  a{
    font-family: 'font1', Avenir, Helvetica, Arial, sans-serif;
    text-decoration: none;
    color: black;
  }
  
  .logo {
  width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;  
  opacity: 0.1;
  pointer-events: none;
}
  </style>